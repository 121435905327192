exports.components = {
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-02-27-how-to-start-dev-career-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-02-27-how-to-start-dev-career.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-02-27-how-to-start-dev-career-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-03-13-understand-the-basics-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-03-13-understand-the-basics.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-03-13-understand-the-basics-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-03-22-software-analytics-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-03-22-software-analytics.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-03-22-software-analytics-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-03-enterprise-vs-open-source-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-04-03-enterprise-vs-open-source.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-03-enterprise-vs-open-source-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-15-configure-windows-terminal-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-04-15-configure-windows-terminal.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-15-configure-windows-terminal-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-24-pscore-ohmyposh-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-04-24-pscore-ohmyposh.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-04-24-pscore-ohmyposh-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-05-26-web-dev-tools-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-05-26-web-dev-tools.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-05-26-web-dev-tools-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-06-07-html-minimum-required-knowledge-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-06-07-html-minimum-required-knowledge.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-06-07-html-minimum-required-knowledge-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-06-16-css-minimum-required-knowledge-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-06-16-css-minimum-required-knowledge.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-06-16-css-minimum-required-knowledge-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-04-git-and-github-minimum-required-knowledge-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-07-04-git-and-github-minimum-required-knowledge.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-04-git-and-github-minimum-required-knowledge-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-10-the-businessman-commersant-project-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-07-10-the-businessman-commersant-project.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-10-the-businessman-commersant-project-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-17-the-businessman-initial-commit-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-07-17-the-businessman-initial-commit.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-07-17-the-businessman-initial-commit-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-08-28-publish-to-github-pages-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-08-28-publish-to-github-pages.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-08-28-publish-to-github-pages-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-09-04-the-businessman-main-screen-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-09-04-the-businessman-main-screen.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-09-04-the-businessman-main-screen-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-09-26-the-businessman-basic-interactivity-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-09-26-the-businessman-basic-interactivity.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-09-26-the-businessman-basic-interactivity-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-10-02-the-businessman-basics-of-refactoring-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-10-02-the-businessman-basics-of-refactoring.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-10-02-the-businessman-basics-of-refactoring-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-12-09-the-businessman-events-handling-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2023-12-09-the-businessman-events-handling.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2023-12-09-the-businessman-events-handling-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-01-14-the-businessman-dialogs-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-01-14-the-businessman-dialogs.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-01-14-the-businessman-dialogs-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-01-28-the-businessman-bank-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-01-28-the-businessman-bank-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-01-28-the-businessman-bank-logic-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-10-the-businessman-exchange-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-02-10-the-businessman-exchange-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-10-the-businessman-exchange-logic-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-11-the-businessman-market-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-02-11-the-businessman-market-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-11-the-businessman-market-logic-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-25-the-businessman-assets-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-02-25-the-businessman-assets-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-02-25-the-businessman-assets-logic-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-03-20-the-businessman-assistant-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-03-20-the-businessman-assistant-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-03-20-the-businessman-assistant-logic-mdx" */),
  "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-04-24-the-businessman-end-month-logic-mdx": () => import("./../../../src/components/article.tsx?__contentFilePath=/home/runner/work/sergii-dev/sergii-dev/articles/2024-04-24-the-businessman-end-month-logic.mdx" /* webpackChunkName: "component---src-components-article-tsx-content-file-path-home-runner-work-sergii-dev-sergii-dev-articles-2024-04-24-the-businessman-end-month-logic-mdx" */),
  "component---src-components-category-tsx": () => import("./../../../src/components/category.tsx" /* webpackChunkName: "component---src-components-category-tsx" */),
  "component---src-components-project-tsx": () => import("./../../../src/components/project.tsx" /* webpackChunkName: "component---src-components-project-tsx" */),
  "component---src-components-tag-tsx": () => import("./../../../src/components/tag.tsx" /* webpackChunkName: "component---src-components-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */)
}

